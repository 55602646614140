.layout .react-grid-item > .react-resizable-handle {
	z-index: 120;
}

.draftjs-editor-wrapper figure {
	margin: 0;
}

.text-align-justify {
	text-align: justify;
}

.text-align-center {
	text-align: center;
}

.text-align-left {
	text-align: left;
}

.text-align-right {
	text-align: right;
}
